import React, { Component } from 'react'
import $ from 'jquery'
// import './base.js'

//antd组件
import { Row, Col, Input, Button, message } from 'antd'
// 组件
import PageBg from '../../components/PageBg/PageBg'

//变量
import { mobileW } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim'
import ScrollAnim from 'rc-scroll-anim'
// 样式
import './SaaS.less'
import './base.css'
import Background from '../../static/images/S2b2cbg.png'
import Background2 from '../../static/images/S2b2cbg2.png'
import Background3 from '../../static/images/S2b2C/dengbg.png'
import Background4 from '../../static/images/S2b2C/xiaochengxbg2.png'
import Background5 from '../../static/images/S2b2C/ledabg.png'
import requestw from '../../utils/requestw';
const ScrollOverPack = ScrollAnim.OverPack
const { TextArea } = Input;
//菜单数据

export default class SaaS extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: false,
      teamList1: [
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem1.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            float: 'left',
            textAlign: 'center',
            lineHeight: '73px',
            border: '1px solid #D9D9D9',
          },
          imgsty: {
            width: '110px',
            height: '36px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem2.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            textAlign: 'center',
            float: 'left',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '110px',
            height: '33px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem3.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            border: '1px solid #D9D9D9',
            textAlign: 'center',
            float: 'left',
            lineHeight: '73px',
          },
          imgsty: {
            width: '77px',
            height: '42px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem4.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            border: '1px solid #D9D9D9',
            height: '73px',
            textAlign: 'center',
            float: 'left',
            lineHeight: '73px',
          },
          imgsty: {
            width: '127px',
            height: '40px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem5.png'),

          styl: {
            width: '186px',
            border: '1px solid #D9D9D9',
            height: '73px',
            float: 'left',
            textAlign: 'center',
            lineHeight: '73px',
          },
          imgsty: {
            width: '137px',
            height: '32px',
          },
        },
      ],

      teamList2: [
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem6.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            float: 'left',
            textAlign: 'center',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '141px',
            height: '34px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem7.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            textAlign: 'center',
            float: 'left',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '117px',
            height: '34px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem8.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            border: '1px solid #D9D9D9',
            textAlign: 'center',
            float: 'left',
            lineHeight: '73px',
          },
          imgsty: {
            width: '141px',
            height: '37px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem9.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            textAlign: 'center',
            float: 'left',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '130px',
            height: '34px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem10.png'),

          styl: {
            width: '186px',
            height: '73px',
            float: 'left',
            textAlign: 'center',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '97px',
            height: '34px',
          },
        },
      ],

      teamList3: [
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem11.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            float: 'left',
            textAlign: 'center',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '144px',
            height: '25px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem12.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            textAlign: 'center',
            float: 'left',
            lineHeight: '73px',
            border: '1px solid #D9D9D9',
          },
          imgsty: {
            width: '68x',
            height: '43px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem13.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            textAlign: 'center',
            float: 'left',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '128px',
            height: '30px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem14.png'),
          styl: {
            marginRight: '36px',
            width: '186px',
            height: '73px',
            textAlign: 'center',
            border: '1px solid #D9D9D9',
            float: 'left',
            lineHeight: '73px',
          },
          imgsty: {
            width: '111px',
            height: '36px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem15.png'),

          styl: {
            width: '186px',
            height: '73px',
            float: 'left',
            textAlign: 'center',
            border: '1px solid #D9D9D9',
            lineHeight: '73px',
          },
          imgsty: {
            width: '54px',
            height: '43px',
          },
        },
      ],
      blueList: [
        {
          url: require('../../static/images/S2b2C/blueitem1.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem2.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem3.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem4.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem5.png'),
          styl: { width: '162px', height: '162px' },
        },
      ],
      greeList: [
        {
          url: require('../../static/images/S2b2C/greeitem1.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem2.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem3.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem4.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem5.png'),
          styl: { width: '162px', height: '162px' },
        },
      ],
      //企业大数据源
      shujuyuanList: [
        {
          title: '行为数据源',
          tips: '因用户个人个体行为而产生的数据',
          tips2: '门店客流、APP行为、客户评论等',
          img: require('../../static/images/newretail/shujuyuan/xwsjy.png'),
          size: { w: 125, h: 122 },
        },
        {
          title: '商业经营数据来源',
          tips: '围绕交易和内部管理系统运行产生的数据',
          tips2: '交易订单、供应链、智能管理等',
          img: require('../../static/images/newretail/shujuyuan/syjysjly.png'),
          size: { w: 128, h: 128 },
        },
        {
          title: '技术运维数据源',
          tips: '在技术产品运行过程中产生的数据',
          tips2: '云监控、运行日志、系统异常等',
          img: require('../../static/images/newretail/shujuyuan/jsywsjy.png'),
          size: { w: 131, h: 116 },
        },
        {
          title: '第三方数据源',
          tips: '第三方公司所公开提供的一些数据',
          tips2: '腾讯微信、国家统计局、网络应用等',
          img: require('../../static/images/newretail/shujuyuan/dsfsjy.png'),
          size: { w: 125, h: 92 },
        },
      ],
      //整合
      zhengheList: [
        {
          title: '客户触点',
          img: require('../../static/images/newretail/zhenghe/khcd.png'),
          left: -67, //控制位置
          aniType: ['left', 'right'],
          delay: 100,
        },
        {
          title: '门店触点',
          img: require('../../static/images/newretail/zhenghe/mdcd.png'),
          left: -39, //控制位置
          aniType: ['left', 'right'],
          delay: 0,
        },
        {
          title: '总部触点',
          img: require('../../static/images/newretail/zhenghe/zbcd.png'),
          left: 64, //控制位置
          aniType: ['right', 'left'],
          delay: 100,
        },
        {
          title: '数字触点',
          img: require('../../static/images/newretail/zhenghe/szcd.png'),
          left: 65, //控制位置
          aniType: ['right', 'left'],
          delay: 200,
        },
      ],
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
    this.runJq()
  }
  runJq() {
    $(function () {
      //导航菜单
      var ytx = {}
      $('[t_nav]').hover(
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideDown(200)
          }, 150)
        },
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideUp(200)
          }, 150)
        }
      )

      //导航绿色标题高度处理
      $('.submenu dl').each(function () {
        var dl_h = $(this).height()
        $(this)
          .find('dt')
          .height(dl_h)
      })

      //首页短信、im、语音、视频动画效果
      $('.box1 ul li').hover(
        function () {
          $(this)
            .find('.unhover')
            .find('i')
            .animate({ bottom: -145, opacity: '0' }, 500)
          $(this)
            .find('.unhover')
            .find('.txt')
            .animate({ left: -125, opacity: '0' }, 500)
          $(this)
            .find('.hover')
            .show()
            .find('i')
            .animate({ top: 0 }, 500)
          $(this)
            .find('.hover')
            .show()
            .find('.txt')
            .animate({ right: 0 }, 500)
        },
        function () {
          $(this)
            .find('.unhover')
            .find('i')
            .animate({ bottom: 0, opacity: '1' }, 500)
          $(this)
            .find('.unhover')
            .find('.txt')
            .animate({ left: 0, opacity: '1' }, 500)
          $(this)
            .find('.hover')
            .find('i')
            .animate({ top: -125 }, 500)
          $(this)
            .find('.hover')
            .find('.txt')
            .animate({ right: -110 }, 500)
        }
      )

      //首页“简单”、“可靠”、“专注”、“全球”动画效果
      var current = $('.index_2 span.txt.current').index()
      $('.index_2 span.txt')
        .not('.current')
        .hover(
          function () {
            var span_index = $(this).index()
            $(this).addClass('current')
            $('.txt_desc')
              .find('span')
              .eq(span_index)
              .fadeIn(800)
              .siblings('span')
              .fadeOut(800)
          },
          function () {
            var span_index = $(this).index()
            $(this).removeClass('current')
            $('.txt_desc')
              .find('span')
              .eq(span_index)
              .fadeOut(800)
              .siblings('span')
              .eq(current)
              .fadeIn(800)
          }
        )

      //首页客户图标鼠标放上去状态变化
      $('.index_4 ul li').hover(
        function () {
          var img_src = $(this)
            .find('img')
            .attr('src')
          var img_name = img_src.substring(7).replace('.png', '')
          //alert(img_name);
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '_hover.png')
        },
        function () {
          var img_src = $(this)
            .find('img')
            .attr('src')
          var img_name = img_src.substring(7).replace('_hover.png', '')
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '.png')
        }
      )

      //语音通知手风琴效果
      $('.voice_2 ul li').each(function () {
        var fold = $(this).find('.fold')
        var unfold = $(this).find('.unfold')
        if (fold.is(':hidden')) {
          $(this).width(445)
        } else {
          $(this).width(200)
        }
      })

      $('.voice_2 ul li').click(function () {
        var li_index = $(this).index()
        $(this).animate({ width: 445 }, 200)
        $(this)
          .find('.unfold')
          .show()
        $(this)
          .find('.fold')
          .hide()
        $(this)
          .siblings()
          .animate({ width: 200 }, 200)
        $(this)
          .siblings()
          .find('.unfold')
          .hide()
        $(this)
          .siblings()
          .find('.fold')
          .show()
      })

      //下拉框处理
      $('div.select_box ul li:even').css('background', '#f5f5f5')

      $('div.select_box').click(function (e) {
        if ('readonly' == $(this).attr('readonly')) {
          return false
        }
        e.stopPropagation()
        $(this)
          .children('ul')
          .toggle()
        $(this).toggleClass('focus')
      })
    })
  }
  getAdd() {
    console.log(this)
    let name = this.refs.name.state.value
    let phone = this.refs.phone.state.value
    let teamName = this.refs.teamName.state.value
    let type = document.getElementById('types').value
    // let type = this.refs.type.state.value
    console.log(name, phone, teamName, type)
    this.setState({ isloading: true })
    console.log(type);
    if (name !== undefined && name !== '') {
      if (phone !== undefined && phone !== '') {
        if (teamName !== undefined && teamName !== '') {
          // if (type !== undefined && type !== '') {
            setTimeout(async() => {
              this.setState({ isloading: false })
              let res =await requestw ({
                url:'https://lubanweb.bld365.com/api/home/fillIn',
                data:{
                 userName:name,
                //  userType:this.state.modaltitle=='我是劳动者'? 'PERSON' :'COMPANY',
                 phoneNumber:phone,
                 userType:'INSTALL',
                 userPosition:teamName,
                 remark:type
               },
              })
              if (res.code=='0') {
                message.success('提交成功')
              }else{
                message.warning(res.data?res.data:'提交失败');

              }
              // message.success('提交成功')
            }, 1000)
          // } else {
          //   setTimeout(() => {
          //     message.success('提交成功')
          //     // message.error('请输入备注')
          //     this.setState({ isloading: false })
          //   }, 1000)
          // }
        } else {
          setTimeout(() => {
            message.error('请输入职位名称')
            this.setState({ isloading: false })
          }, 1000)
        }
      } else {
        setTimeout(() => {
          message.error('请输入联系电话')
          this.setState({ isloading: false })
        }, 1000)
      }
    } else {
      setTimeout(() => {
        message.error('请输入姓名')
        this.setState({ isloading: false })
      }, 1000)
    }
  }
  toipt() {
    window.location.hash = '#abc'
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      shujuyuanList, //企业大数据源
      zhengheList, //整合
      blueList, //
      greeList,
      teamList1,
      teamList2,
      teamList3,
      isloading,
    } = this.state

    //企业大数据源
    let shujuyuan = shujuyuanList.map((obj, index) => (
      <Col key={index} span={6}>
        <QueueAnim
          type={['bottom', 'top']}
          leaveReverse={true}
          forcedReplay={true}
          delay={10 + index * 100}
        >
          <div key={index} className="flexColumn flexJCenter">
            <div style={{ width: '58px', height: '58px' }}>
              <img
                src={obj.img}
                alt=""
                style={{
                  width: obj.size.w > obj.size.h ? '100%' : 'auto',
                  height: obj.size.w > obj.size.h ? 'auto' : '100%',
                }}
              />
            </div>
            <div
              style={{
                fontSize: '17px',
                color: '#000',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              {obj.title}
            </div>
            <div style={{ fontSize: '12px', color: '#4a4a4a' }}>{obj.tips}</div>
            <div style={{ fontSize: '12px', color: '#4a4a4a' }}>
              {obj.tips2}
            </div>
          </div>
        </QueueAnim>
      </Col>
    ))
    //整合
    let zhenghe = zhengheList.map((obj, index) => (
      <Col key={index} span={6}>
        <QueueAnim
          type={obj.aniType}
          leaveReverse={true}
          forcedReplay={true}
          delay={10 + obj.delay}
          duration={900}
        >
          <div
            key={index}
            className="flexColumn flexJCenter"
            style={{ position: 'relative', left: obj.left }}
          >
            <img
              src={obj.img}
              alt=""
              style={{ width: '58px', height: '58px' }}
            />
            <div style={{ fontSize: '17px', color: '#000', marginTop: '10px' }}>
              {obj.title}
            </div>
          </div>
        </QueueAnim>
      </Col>
    ))
    const sty = {
      // backgroundColor: '#fff',
      paddingTop: '20px',
      paddingBottom: '90px',
      backgroundImage: `url(${Background})`,
      // backgroundSize: '100% 100%',
    }
    const sty2 = {
      backgroundImage: `url(${Background2})`,
    }
    const dengbg = {
      backgroundImage: `url(${Background3})`,
      height: '817px',
    }
    const dengbg2 = {
      backgroundImage: `url(${Background4})`,
      height: '722px',
    }
    const dengbg5 = {
      backgroundImage: `url(${Background5})`,
      height: '722px',
    }
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="newretail_page">
        <PageBg
          bgSrc={require('../../static/images/S2b2C/SaaSbg3.png')}
          // titleM="云商城"
          // titleM="全渠道小程序新零售解决方案"
          // descp="助力企业构建私域流量进入品牌新零售新时代"
          titleM={
            <>
              {/* <p
                style={{
                  fontSize: '48px',
                  // marginLeft: '-16x',
                  marginBottom: '8px',
                  textAlign: 'center',
                }}
              >
                云商城
              </p> */}
              <p
                style={{
                  fontSize: '28px',
                  textAlign: 'center',
                  marginTop: '16%',
                  // marginLeft: '-14px'
                }}
              >
                “云派工”APP是一个专注于家居家电领域的售后服务平台
              </p>
              <p
                style={{
                  fontSize: '18px',
                  // marginLeft: '29px',
                  textAlign: 'center',
                  color: 'rgba(255,255,255,1)',
                  marginTop: '15px',
                }}
              >
                为家居家电师傅提供安装、维修、保养等售后服务订单 <br/><div style={{marginTop:5,width:'100%',textAlign:'center'}}>师傅可自由接单、赚取收入、培训专业技能</div>
              </p>
              {/* <p style={{ textAlign: 'center' }} onClick={this.toipt}>
                <button
                  style={{
                    width: '173px',
                    height: '50px',
                    fontSize: '15px',
                    borderRadius: '8px',
                    fontFamily: 'PingFangSC-Thin,PingFang SC',
                    fontWeight: '100',
                    color: 'rgba(255,255,255,1)',
                    lineHeight: '36px',
                    background: 'none',
                    border: '1px solid rgba(255,255,255,1)',
                    margin: '0 auto',
                  }}
                >
                  联系商务
                </button>
              </p> */}
            </>
          }
        // contentStyle={{
        //   marginLeft: '-240px',
        //   width: '560px',
        // }}
        // descp="从完成当月的目标到参与未来交易预测，保证生意始终符合您的预期"
        />

        {/* 企业大数据源 */}
        {/* <div className="shujuyuan overhidden common_item_wrap" style={sty}>
          <div
            className="flexColumn newretail_titlewrap"
            style={{
              marginBottom: '50px',
            }}
          >
            <div className="newretail_titlewrap_title">S2b2C模式演进</div>

         
          </div>
          <div
            style={{
              width: '100%',
              height: '502px',
            }}
          >
            <div style={{ margin: '0 auto', width: '1213px', height: '502px', position: 'relative' }}>
              <img
                src={require('../../static/images/S2b2cwang.png')}
                style={{
                  width: '1213px',
                  height: '502px',
                }}
                alt=""
              />
              <div style={{ position: 'absolute', left: '200px', bottom: '90px', fontSize: '18px', fontWeight: '400' }}>
                <p style={{ marginBottom: '5px' }}>消费互联网：信息对称+效率+用户体验</p>
                <p>产业互联网：效率提升+消费互联网服务</p>
              </div>
            </div>
          </div>
        </div> */}
            <img style={{width:'100%'}} src={require('../../static/images/home/nengli/datu6.png')} /> 
            <img style={{width:'100%'}} src={require('../../static/images/home/nengli/datu7.png')} /> 
            <img style={{width:'100%'}} src={require('../../static/images/home/nengli/datu5.png')} /> 
            <img style={{width:'100%'}} src={require('../../static/images/home/nengli/datu8.png')} /> 

        {/* 关系图 */}
        {/* <div className="guanxi overhidden common_item_wrap" style={sty2}>
          <div className="flexColumn newretail_titlewrap">
            <div
              className="newretail_titlewrap_title"
              style={{ color: '#fff' }}
            >
              S2b2C模式演进
            </div>
         
          </div>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            className="common_wrap "
            style={{
              width: '1189px',
              height: '626px',
              zoom: '0.8',
            }}
          >
            <img
              src={require('../../static/images/S2b2cliucheng.png')}
              style={{
                width: '100%',
                height: '90%',
                margin: '0 auto',
              }}
              alt=""
            />
         
          </Row>
          <div
            className="common_wrap flexCenter flexBetween"
            style={{
              color: 'rgba(255,255,255,0.8)',
              padding: '0 152px',
              margin: '20px auto 35px',
            }}
          >
            <div
              style={{
                width: '507px',
                height: '44px',
                textAlign: 'center',
                margin: '0 auto',
                fontSize: '16px',
              }}
            >
              优化流程可以让效率提示30%，重构流程可以让效率至少提升100%
              重构生产要素从而达到目的，就像马车升级成汽车。
            </div>
          </div>
        </div> */}

        {/* 整合 */}
        <div className="zhenghe overhidden common_item_wrap">
          {/* <div
            className="flexColumn newretail_titlewrap"
            style={{ marginBottom: '55px' }}
          >
            <div className="newretail_titlewrap_title">S2b2C模式</div>
          
          </div>
       */}

          {/* <div
            className="common_wrap flexCenter flexBetween"
            style={{ margin: '36px auto' }}
          >
            <div style={{ width: '100%', height: '950px', margin: '0 auto' }}>
              <div className="S2btitle">S对b赋能的5个维度</div>
              {blueList.map((item, ind) => (
                <img src={item.url} style={item.styl} alt="" />
              ))}
              <div className="S2btitle" style={{ marginTop: '58px' }}>
                b对C提供深度服务
              </div>
              {greeList.map((item, ind) => (
                <img src={item.url} style={item.styl} alt="" />
              ))}
              <div className="S2btitle" style={{ marginTop: '58px' }}>
                丰富多元的品牌营销场景
              </div>
              <div class="kePublic">
                <div class="item_box box10">
                  <div class="item_box_wp">
                    <div class="voice_2">
                      <ul>
                        <li class="li1" id="li1" style={{ width: ' 445px' }}>
                          <div class="fold" style={{ display: 'none' }}>
                            <span class="img" />
                            <span class="txt">私域流量转化</span>
                          </div>
                          <div class="unfold" style={{ display: 'block' }}>
                            <dl>
                              <dt>
                                <img
                                  src={require('../../static/images/S2b2C/redicon1.png')}
                                  alt=""
                                />
                                <span>私域流量转化</span>
                              </dt>
                              <dd />
                              <dd style={{ fontSize: '13px' }}>
                                生意的本质是流量+变现”为理念以人为中心的载体，朋友互相认识、自我介绍的有效方法。由于线下流量太过分散，商户没有有效的工具进行吸收、存储、和转化，并且线上流量很难获取，让每个人都拥有一个专属的商城，做自己圈子的生意。
                              </dd>
                            </dl>
                          </div>
                        </li>
                        <li class="li2" style={{ width: ' 200px' }}>
                          <div class="fold" style={{ display: 'block' }}>
                            <span class="img" />
                            <span class="txt">深度用户服务</span>
                          </div>
                          <div class="unfold" style={{ display: 'none' }}>
                            <dl>
                              <dt>
                                <img
                                  src={require('../../static/images/S2b2C/redicon2.png')}
                                  alt=""
                                />
                                <span>深度用户服务</span>
                              </dt>
                              <dd />
                              <dd style={{ fontSize: '13px' }}>
                                从流量和客户归属角度，云商城上所有客户所有流量都归属于企业自己，都在自己企业小程序里面，而在传统服务的质量来讲，云商城经过真实的信息认证进一步增加了供需双方的信任感，而传统电商多以客服为主。
                              </dd>
                            </dl>
                          </div>
                        </li>
                        <li class="li3" style={{ width: ' 200px' }}>
                          <div class="fold" style={{ display: 'block' }}>
                            <span class="img" />
                            <span class="txt">快速销售转化</span>
                          </div>
                          <div class="unfold" style={{ display: 'none' }}>
                            <dl>
                              <dt>
                                <img
                                  src={require('../../static/images/S2b2C/redicon3.png')}
                                  alt=""
                                />
                                <span>快速销售转化</span>
                              </dt>
                              <dd />
                              <dd style={{ fontSize: '13px' }}>
                                云商城不仅可实现名片所示信息一键存入通讯录功能，其核心更是“熟人电商”，把商品跟人跟人关联在一起，赋予销售力量，帮助企业解决获客与社交问题，通过名片来打造一个从社交到成交的闭环
                              </dd>
                            </dl>
                          </div>
                        </li>
                        <li class="li4" style={{ width: ' 200px' }}>
                          <div class="fold" style={{ display: 'block' }}>
                            <span class="img" />
                            <span class="txt">营销成本降低</span>
                          </div>
                          <div class="unfold" style={{ display: 'none' }}>
                            <dl>
                              <dt>
                                <img
                                  src={require('../../static/images/S2b2C/redicon4.png')}
                                  alt=""
                                />
                                <span>营销成本降低</span>
                              </dt>
                              <dd />
                              <dd style={{ fontSize: '13px' }}>
                                从流量获取角度来讲，云商城是用人去获取的，成本是很低的。而传统电商则是依赖于广告，因为广告位数量，所以只会导致广告越来越贵。
                              </dd>
                            </dl>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      

          <div className="zhenghe overhidden common_item_wrap" style={dengbg}>
            <div
              className="flexColumn newretail_titlewrap"
              style={{
                marginBottom: '50px',
              }}
            >
              <div
                className="newretail_titlewrap_title"
                style={{ color: '#fff', marginTop: '30px' }}
              >
                产品中心
              </div>
            
            </div>
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '96px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuleft1" />
              <div className="xiaochengxuright1">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云商城
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为企业实现人人都是销售员，实现全员营销传播
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  商品分享丨下单支付丨订单查询等
                </p>
                <div
                  style={{ width: '116px', height: '116px', margin: '0 auto' }}
                >
                  <img
                    style={{ width: '116px', height: '116px' }}
                    src={require('../../static/images/S2b2C/chengdanicon.png')}
                    alt=""
                  />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  成单小店-由云商城生成
                </p>
              </div>
            </div>
          </div>

          <div
            className="zhenghe overhidden common_item_wrap"
            style={{ background: '#fff', color: '#000' }}
          >
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '76px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuright2">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云名片
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为企业产品与资料信息提供宣传与推广服务
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  编辑名片丨资料展示丨数据统计等
                </p>
                <div
                  style={{ width: '116px', height: '116px', margin: '0 auto' }}
                >
                  <img
                    style={{ width: '116px', height: '116px' }}
                    src={require('../../static/images/S2b2C/jiesuanicon.png')}
                    alt=""
                  />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  节算宝名片-由云名片生成
                </p>
              </div>
              <div className="xiaochengxuleft2" />
            </div>
          </div>

          <div className="zhenghe overhidden common_item_wrap" style={dengbg2}>
          
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '96px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuleft3" />
              <div className="xiaochengxuright3">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云派工
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为灵活用工个人提供抢单，完成任务并记录佣金结算服务
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  扩大销量丨增加收入丨减少成本等
                </p>
                <div
                  style={{ width: '116px', height: '116px', margin: '0 auto' }}
                >
                  <img
                    style={{
                      width: '116px',
                      height: '116px',
                    }}
                    src={require('../../static/images/S2b2C/lovelingicon.png')}
                    alt=""
                  />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  爱灵工小程序-由云派工生成
                </p>
              </div>
            </div>
          </div>

          <div
            className="zhenghe overhidden common_item_wrap"
            style={{ background: '#fff', color: '#000' }}
          >
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '76px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuright2">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云学院
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                    width: '540px',
                    marginLeft: '-50px',
                  }}
                >
                  为企业提供用户行为轨迹大数据分析，消费者意向，商机预测
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  编辑名片丨资料展示丨数据统计等
                </p>
                <div
                  style={{ width: '116px', height: '116px', margin: '0 auto' }}
                >
                  <img
                    style={{ width: '116px', height: '116px' }}
                    src={require('../../static/images/S2b2C/xyicon.png')}
                    alt=""
                  />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  安全服务宝-由云学院生成
                </p>
              </div>
              <div className="xiaochengxuleft6" />
            </div>
          </div>
          <div className="zhenghe overhidden common_item_wrap" style={dengbg5}>
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '96px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuleft4" />
              <div className="xiaochengxuright3">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云结算
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为用户提供实名认证、签约与结算服务
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  实名认证丨领取任务丨佣金记录
                </p>
                <div
                  style={{ width: '116px', height: '116px', margin: '0 auto' }}
                >
                  <img
                    style={{
                      width: '116px',
                      height: '116px',
                    }}
                    src={require('../../static/images/S2b2C/leicon.png')}
                    alt=""
                  />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  乐薪-由云结算生成
                </p>
              </div>
            </div>
          </div>

          <div
            className="zhenghe overhidden common_item_wrap"
            style={{ backgroundColor: '#fff' }}
          >
            <div
              className="flexColumn newretail_titlewrap"
              style={{ marginBottom: '35px' }}
            >
              <div className="newretail_titlewrap_title">服务客户</div>
              <div className="newretail_titlewrap_descp">
                目前节算宝共享经济综合服务平台的签约合作客户涵盖通信、金融、家电、零售和互联网等多个行业
              </div>
            </div>
            <div
              className="common_wrap  flexBetween"
              style={{
                margin: '76px auto',
                height: '230px',
                width: '1076px',
              }}
            >
              <div
                style={{ width: '100%', height: '75px', marginBottom: '18px' }}
              >
                {' '}
                {teamList1.map((item, ind) => (
                  <div style={item.styl}>
                    <img style={item.imgsty} src={item.url} alt="" />
                  </div>
                ))}
              </div>
              <div
                style={{ width: '100%', height: '75px', marginBottom: '18px' }}
              >
                {' '}
                {teamList2.map((item, ind) => (
                  <div style={item.styl}>
                    <img style={item.imgsty} src={item.url} alt="" />
                  </div>
                ))}
              </div>
              <div style={{ width: '100%', height: '75px' }}>
                {' '}
                {teamList3.map((item, ind) => (
                  <div id="abc" style={item.styl}>
                    <img style={item.imgsty} src={item.url} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div> */}

          <div
            className="zhenghe overhidden common_item_wrap"
            style={{ backgroundColor: '#F7F7F7' }}
          >
            <div
              className="flexColumn newretail_titlewrap"
              style={{ marginBottom: '35px' }}
            >
              <div className="newretail_titlewrap_title">
              免费申请入驻
              </div>
              {/* <div className="newretail_titlewrap_descp">
                留下您的联系方式，我们将有专人与您联系
              </div> */}
            </div>
            <div
              className="common_wrap  flexBetween"
              style={{
                margin: '76px auto',
                height: '118px',
                width: '1076px',
              }}
            >
              <div
                style={{
                  width: '30%',
                  height: '38px',
                  float: 'left',
                  marginRight: '32px',
                  marginBottom: '30px',
                }}
              >
                <Input ref="name" placeholder="请输入您的姓名" />
              </div>
              <div
                style={{
                  width: '30%',
                  height: '38px',
                  float: 'left',
                  marginBottom: '30px',
                }}
              >
                <Input ref="phone" placeholder="请输入您的联系电话" />
              </div>
              <div
                style={{
                  width: '30%',
                  height: '38px',
                  float: 'right',
                  // marginRight: '32px',
                  marginBottom: '30px',
                  margin:'0 32px',
                }}
              >
                <Input ref="teamName" placeholder="请填写您感兴趣的职位" />
              </div>
              <div
                style={{
                  width: '97%',
                  height: '88px',
                  float: 'left',
                  marginBottom: '30px',
                }}
              >
                <TextArea ref="type" id='types' style={{height:90,maxHeight:90}}  placeholder="请输入留言内容" />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '48px',
                  float: 'right',
                  marginBottom: '30px',
                  marginRight: '32px',
                }}
              >
                <Button
                  style={{
                    width: '193px',
                    height: '38px',
                    background: '#1e2540',
                    color: '#fff',
                    float: 'right',
                  }}
                  onClick={(e) => this.getAdd()}
                  loading={isloading}
                >
                  提交
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
