import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
//路由
import { Link } from 'react-router-dom'
//变量
import { mobileW } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim'
import ScrollAnim from 'rc-scroll-anim'
// 样式
import './Footer.less'

const ScrollOverPack = ScrollAnim.OverPack

const QRcodeSrc = require('../../static/images/qr_appcenter.png')

class Footer extends Component {
  clickTab = (e) => {
    let ele = e.target
    let idx = Number(ele.getAttribute('data-index'))
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(idx))
  }
  goProductIndex = () => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(1))
  }
  goLexin = () => {
    // const { dispatch } = this.props
    // dispatch(adActions.changeTabIndex(3))
    window.location.href = 'https://lexin.bld365.com/#/user/login'
  }
  render() {
    return (
      <div className="footer">
        <div className="flexCenter flexAround container_wrap">
          <div className="container">
            <div className="title">便利电科技有限公司</div>
            <div className="container_content">
              <Link to="/aboutus" data-index={4}>
                联系电话：400-060-8060
              </Link>
              <Link to="/joinus" data-index={5}>
                电子邮箱：lnbld@bld365.com
              </Link>
              <Link to="/qualification" data-index={6}>
                公司地址：辽宁省沈阳市浑南区金辉街1号德宝大厦809
              </Link>
            </div>
          </div>
          {/* <div className="container">
            <div className="title">产品中心</div>
            <div className="container_content">
              <div>云名片</div>
              <div>云学院</div>
              <div>云商城</div>
              <div onClick={this.goLexin}>云结算</div>
              <div>云派工</div>
              <div>云直播</div>
            </div>
          </div>
          <div className="container">
            <div className="title">联系我们</div>
            <div className="container_content">
              <div>400-086-0606</div>
              <div>周一至周日: 08:00~18:00</div>
            </div>
          </div> */}
          <div className="container">
            {window.screen.width > mobileW ? (
              <div style={{ width: '105px', height: '105px' }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <QueueAnim
                    type={['right', 'left']}
                    leaveReverse={true}
                    forcedReplay={true}
                    delay={10}>
                    <img key="1" className="qrCode" src={QRcodeSrc} alt="" />
                  </QueueAnim>
                </ScrollOverPack>
              </div>
            ) : (
              <div style={{ width: '105px', height: '105px' }}>
                <QueueAnim
                  type={['right', 'left']}
                  leaveReverse={true}
                  forcedReplay={true}
                  delay={10}>
                  <img key="1" className="qrCode" src={QRcodeSrc} alt="" />
                </QueueAnim>
              </div>
            )}
          </div>
        </div>

        <div className="footer_bot flexCenter flexJCenter">
          <a href="https://beian.miit.gov.cn/" style={{ color: '#fff' }}>
            京ICP备2020038969号-3
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad
})

export default connect(mapStateToProps)(Footer)
