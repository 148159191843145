import { allHostObj } from './consts';
export const mobileW = 600

//走马灯下面文字
export const carouselListText = [
  "产业互联网化专家",
  "高新技术企业",
  "双软认证企业",
  "国际CMMI3级认证",
  "产品覆盖全国"
]
export const globalHost = () => {
  if (window.isProd == '//luban.bld365.com/') {
    // 生产《域名》
    return allHostObj.proHost.host;
  } else if (window.isProd == '//lubanpre.bld365.com/') {
    return allHostObj.proHostPor.host;
  }
  // 测试环境《域名》
  return allHostObj.devHost.host;
};

