import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
//antd组件
import {
  Row, Col,Input, Button, message
} from 'antd';
// 组件
import PageBg from '../../components/PageBg/PageBg'
//变量
import { mobileW } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
// 样式
import requestw from '../../utils/requestw';
import './Lexin.less'

const ScrollOverPack = ScrollAnim.OverPack;
const { TextArea } = Input;

class Lexin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //遇到问题
      isloading:false,
      wentiList: [
        {
          title: "无法提供发票",
          tips: "大量个人不懂繁杂的开票流程无法提供发票",
          img: require("../../static/images/lexin/wenti/wenti1.png"),
        }, {
          title: "税负重/成本高",
          tips: "劳务报酬报个税税率最高达45%企业和个人伤不起！",
          img: require("../../static/images/lexin/wenti/wenti2.png"),
        }, {
          title: "三流不合一",
          tips: "私对私转账，找朋友公司开票虚开发票风险",
          img: require("../../static/images/lexin/wenti/wenti3.png"),
        }
      ],
      //为什么选择
      whyList: [
        {
          title: "政策法规保障",
          tips: "税务机关制定委托代征机构",
          img: require("../../static/images/lexin/why/why1.png"),
        }, {
          title: "资深实力雄厚",
          tips: "获得国内知名投资机构投资",
          img: require("../../static/images/lexin/why/why2.png"),
        }, {
          title: "合法合规有保障",
          tips: "拥有会计事务所专业团队设计合规方案",
          img: require("../../static/images/lexin/why/why3.png"),
        }, {
          title: "资金有保障",
          tips: "雄厚资金，平安银行监管安全可靠",
          img: require("../../static/images/lexin/why/why4.png"),
        }
      ],
      whyActiveIndex: "",
      //专属解决方案
      zhuanshuList: [
        // {
        //   title: "B端企业管理",
        //   title2:'管理人员减少40%',
        //   img: require("../../static/images/lexin/zhuanshujiejue/wlhy.png"),
        //   bgImg: require('../../static/images/lexin/zhuanshujiejue/wlhy_bg.png'),
        //   size: { w: 114, h: 92 },
        // },
        {
          title: "B端企业管理",
          title2:'管理人员减少40%',
          img: require("../../static/images/home/nengli/icon.png"),
          bgImg: require('../../static/images/home/nengli/bg1.png'),
          descp: ['减少售后人员成本' ,'销售数据归口管理', '通明化信息管理', '旺季预警管理' ,'自动核算费用'],
        size: { w: 25, h:46 },
        }, {
          title: "网点文员提效",
          title2:'文员减少60%',
          img: require("../../static/images/home/nengli/icon1.png"),
          bgImg: require('../../static/images/home/nengli/bg2.png'),
          descp: ['减少录单工作量','减少和用户沟通问题','自动归类汇总','可视化派单效率高','自动核算工资费用'],
          size: { w: 36, h: 46 },
        }, {
          title: "安装师傅提效",
          title2:'安装效率提升30%',
          img: require("../../static/images/home/nengli/icon2.png"),
          bgImg: require('../../static/images/home/nengli/bg3.png'),
          descp: ['安装费电子结算', '服务费收取容易', '网格化安装提效', '工资核算清晰准确' ,'星级师傅赚的更多'],
          size: { w: 47, h: 46 },
        }, {
          title: "C端用户运营",
          title2:'服务绩效大幅提升',
          img: require("../../static/images/home/nengli/icon3.png"),
          bgImg: require('../../static/images/home/nengli/bg4.png'),
          size: { w: 54, h: 46 },
          descp: ['统送信息实时了解' ,'安装流程清晰掌握', '账目收费清晰可见', '服务费发票线上开具' ,'产品全流程可追溯', '二次运营充分沟通'],
        },
      ],
      zhuanshuActiveIndex: "",
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(2))
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  // 为什么选择active
  whyActive = (index) => {
    this.setState({ whyActiveIndex: index })
  }
  whyActiveOut = () => {
    this.setState({ whyActiveIndex: "" })
  }
  //专属方案active
  zhuanshuActive = (index) => {
    this.setState({ zhuanshuActiveIndex: index })
  }
  zhuanshuActiveOut = () => {
    this.setState({ zhuanshuActiveIndex: "" })
  }
  getAdd() {
    console.log(this)
    let name = this.refs.name.state.value
    let phone = this.refs.phone.state.value
    let teamName = this.refs.teamName.state.value
    let type = document.getElementById('types').value
    console.log(name, phone, teamName, type)
    this.setState({ isloading: true })
    if (name !== undefined && name !== '') {
      if (phone !== undefined && phone !== '') {
        if (teamName !== undefined && teamName !== '') {
          // if (type !== undefined && type !== '') {
            setTimeout(async() => {
              this.setState({ isloading: false })
              let res =await requestw ({
                url:'https://lubanweb.bld365.com/api/home/fillIn',
                data:{
                 userName:name,
                 userType:'COSTFREE',//this.state.modaltitle=='我是劳动者'? 'PERSON' :'COMPANY',
                 phoneNumber:phone,
                 userPosition:teamName,
                 remark:type
               },
              })
              if (res.code=='0') {
                message.success('提交成功')
              }else{
                message.warning(res.data?res.data:'提交失败');

              }
              // message.success('提交成功')
            }, 1000)
          // } else {
          //   setTimeout(() => {
          //     message.success('提交成功')
          //     // message.error('请输入备注')
          //     this.setState({ isloading: false })
          //   }, 1000)
          // }
        } else {
          setTimeout(() => {
            message.error('请输入职位名称')
            this.setState({ isloading: false })
          }, 1000)
        }
      } else {
        setTimeout(() => {
          message.error('请输入联系电话')
          this.setState({ isloading: false })
        }, 1000)
      }
    } else {
      setTimeout(() => {
        message.error('请输入姓名')
        this.setState({ isloading: false })
      }, 1000)
    }
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      wentiList, //遇到问题
      whyList, //为什么选择我们
      zhuanshuList,
      isloading,
    } = this.state

    //遇到问题
    let wenti = wentiList.map((obj, index) => (
      <Col key={index} span={8}>
        <div key={index} className="flexColumn">
          <QueueAnim type={['left', 'right']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + index * 220}
            duration={450 + index * 600}
          >
            <div key={index} className="flexColumn flexJCenter">
              <img src={obj.img} alt='' style={{ width: "112px", height: "112px" }} />
              <div style={{ fontSize: "14px", marginTop: "25px", marginBottom: "5px" }}>{obj.title}</div>
              <div style={{ fontSize: "12px", color: "#4a4a4a" }}>{obj.tips}</div>
            </div>
          </QueueAnim>
        </div>
      </Col>
    ))
    //为什么选择
    let why = whyList.map((obj, index) => (
      <div key={index}
        className={`flexCenter whyItem${index === this.state.whyActiveIndex ? " active" : ""}`}
        onMouseEnter={() => { this.whyActive(index) }}
        onMouseLeave={this.whyActiveOut}
      >
        <img src={obj.img} alt='' style={{ width: '79px', height: "79px", margin: "0px 30px 0 49px" }} />
        <div>
          <div style={{ fontSize: "17px" }}>{obj.title}</div>
          <div style={{ fontSize: "14px", color: "#4A4A4A" }}>{obj.tips}</div>
        </div>
      </div>
    ))
    //解决方案
    let jiejueDom = (
      <div className="common_wrap flex flexJCenter">
        <div style={{ flex: "1 0 0" }}>
          <QueueAnim className="fanganItem"
            type={['left', 'right']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10}
            duration={600}
            style={{ textAlign: "right" }}
          >
            <div key="1">
              <div className="fanganTitle">新零售兼职营销工具</div>
              <div className="fanganTips">服务对象：灵活兼职个人</div>
              <div className="fanganTips">特点：专业、匹配、灵活</div>
            </div>
          </QueueAnim>
          <QueueAnim className="fanganItem"
            type={['left', 'right']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + 350 * 2}
            duration={600}
            style={{ textAlign: "right", marginTop: "28px" }}
          >
            <div key="1">
              <div className="fanganTitle">奖金支付透明</div>
              <div className="fanganTips">针对对象：平台兼职人员</div>
              <div className="fanganTips">特点：数据透明、兑付实时、方便快捷</div>
            </div>
          </QueueAnim>
        </div>
        <div className="flexCenter flexJCenter posiRe" style={{ height: "257px", width: "430px" }}>
          <img src={require("../../static/images/lexin/fangan/lexin_fangan.png")} alt='' style={{ position: "absolute", left: "0", top: "0", width: "100%", height: "100%" }} />
          <div style={{ position: "relative", zIndex: 33, fontSize: "17px", color: "#000" }}>乐薪平台</div>
          {/* 旋转圆圈 */}
          <div className="yuanWrap">
            {/* 小到大 */}
            <img className='positionCenter yuan yuan1' src={require('../../static/images/lexin/fangan/yuan1.png')} alt="" style={{ width: "178px", height: "178px" }} />
            <img className='positionCenter yuan yuan2' src={require('../../static/images/lexin/fangan/yuan2.png')} alt="" style={{ width: "190px", height: "190px" }} />
            <img className='positionCenter yuan yuan3' src={require('../../static/images/lexin/fangan/yuan3.png')} alt="" style={{ width: "202px", height: "202px" }} />
            <img className='positionCenter yuan yuan4' src={require('../../static/images/lexin/fangan/yuan4.png')} alt="" style={{ width: "214px", height: "214px" }} />
            <img className='positionCenter yuan yuan5' src={require('../../static/images/lexin/fangan/yuan5.png')} alt="" style={{ width: "226px", height: "226px" }} />
            <img className='positionCenter yuan yuan6' src={require('../../static/images/lexin/fangan/yuan6.png')} alt="" style={{ width: "238px", height: "238px" }} />
          </div>
        </div>
        <div style={{ flex: "1 0 0" }}>
          <QueueAnim className="fanganItem"
            type={['right', 'left']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + 350 * 1}
            duration={600}
          >
            <div key="1">
              <div className="fanganTitle">业务流程合规</div>
              <div className="fanganTips">解决范围：税筹服务</div>
              <div className="fanganTips">特点：合规成本进项+完税到个人</div>
            </div>
          </QueueAnim>
          <QueueAnim className="fanganItem"
            type={['right', 'left']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + 350 * 3}
            duration={600}
            style={{ marginTop: "28px" }}
          >
            <div key="1">
              <div className="fanganTitle">技术保障方案</div>
              <div className="fanganTips">应用范围：所有客户</div>
              <div className="fanganTips">有技术能力的客户：快速对接企业APP</div>
              <div className="fanganTips">无技术能力的客户：定制化系统+独立APP</div>
            </div>
          </QueueAnim>
        </div>
      </div>
    )
    //专属解决方案
    let zhuanshu = zhuanshuList.map((obj, index) => (
      <div key={index} className={`zhaunshuItem${index === this.state.zhuanshuActiveIndex ? " active" : ""}`}
        onMouseEnter={() => { this.zhuanshuActive(index) }}
        onMouseLeave={this.zhuanshuActiveOut}
        style={{ backgroundImage: `url(${obj.bgImg})`, backgroundSize: "cover",margin:'0 8px  0 15px',borderRadius:10  }}
      >
        <div className="flexCenter flexJCenter zhuanshuImgBox" style={{ width: "50px", height: "50px",marginTop:20}}>
          <img src={obj.img} alt=''
            style={{
              width: obj.size.w > obj.size.h ? "100%" : "auto",
              height: obj.size.w > obj.size.h ? "auto" : "100%",
            }}
          />
        </div>
        <div className="flexColumn flexJCenter zhuanshuTitleWrap">
          <div style={{  color: "#fff", marginTop: "10px" ,fontSize:16}}>{obj.title}</div>
          <div style={{  color: "#fff" ,fontSize:14}}>{obj.title2}</div>
        </div>
        <div className="zhuanshuDescp" style={{ fontSize: "12px", color: "#fff" }}>
          {
            obj.descp.map((item,ind)=>(
            <p style={{textAlign:'center'}} key={ind}>{item}</p>
              ))
          }
        </div>
      </div>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className='lexin_page'>
        <PageBg bgSrc={require('../../static/images/lexin/lexin_bg.png')}
          titleM={<span style={{ fontWeight: '200',fontSize:28 ,marginTop:'18%',display:'block',marginBottom:40}}>为小B提供全场景赋能解决方案<br /><span style={{ fontSize: '18px',marginTop:15,display:'block' }}>云派工通过产品+运营的产业互联网模式，赋能家电服务垂直领域，提升服务效率，提高企业盈利能力，提高服务质量表现，为企业拓展更多商业机会。</span></span>}
          // descp='帮助企业和个人定制薪资筹划、纳税服务方案，合法合规纳税 减轻企业和个人负担'
          descp={
            <p>
              {/* <span>帮助企业和个人定制薪资筹划、纳税服务方案，合法合规纳税</span>
              <br />
              <span>减轻企业和个人负担</span> */}
            </p>
          }
          // contentStyle={{ marginLeft: "-240px", width: "560px" }}
          bottomText={[
            "流程正规",
            "合理税筹",
            "安全稳定",
            "结算快捷",
            "操作简便"
          ]}
        />

        {/* 遇到问题 */}
        {/* <div className="wenti overhidden common_item_wrap" style={{ backgroundColor: "#fff", paddingBottom: "80px" }}>
          <div className="flexColumn lexin_titlewrap" style={{ marginBottom: "50px" }}>
            <div className="lexin_titlewrap_title">
              您是否遇到这样的问题？
            </div>
          </div>
          <div style={{ height: "195px" }}>
            <div className="flexCenter flexBetween common_wrap allSpanWrap">
              <div className='flexCenter flexAround' style={{ width: "195px" }}>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
              </div>
              <div className='flexCenter flexAround' style={{ width: "195px" }}>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
              </div>
            </div>
          </div>
        </div> */}

        {/* 为什么选择 */}
        {/* <div className="why overhidden common_item_wrap" style={{ backgroundColor: "#F9F9F9", paddingBottom: "80px" }}>
          <div className="flexColumn lexin_titlewrap">
            <div className="lexin_titlewrap_title">
              为什么选择我们？
            </div>
            <div className="lexin_titlewrap_descp">
              针对不同通信行业场景的零售业务需求，为通信客户提供一站式新零售技术服务
            </div>
          </div>
          <Row type="flex" justify="center" className='common_wrap'>
            {why}
          </Row>
        </div> */}

        {/* 解决方案 */}
        {/* <div className="fangan overhidden common_item_wrap" style={{ backgroundColor: "#292F59" }}>
          <div className="flexColumn lexin_titlewrap">
            <div className="lexin_titlewrap_title" style={{ color: "#fff" }}>
              综合解决方案
            </div>
            <div className="lexin_titlewrap_descp" style={{ color: "#fff" }}>
              流程正规、安全稳定、操作简单、结算快捷
            </div>
          </div>
          {
            window.screen.width > mobileW ?
              <div style={{ height: "304px" }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  {jiejueDom}
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: "304px" }}>
                {jiejueDom}
              </div>
          }
        </div> */}

        {/* 行业专属解决方案 */}
        <div className="zhuanshu overhidden common_item_wrap" style={{ backgroundColor: "#fff" }}>
          <div className="flexColumn lexin_titlewrap">
            <div className="lexin_titlewrap_title">
            管理赋能为企业解决痛点问题
            </div>
            {/* <div className="lexin_titlewrap_descp">
              针对各行业务特性，打造个性化行业解决方案，为您提供一站式的产品服务
            </div> */}
          </div>
          <div className="common_wrap flexCenter flexJCenter" style={{ margin: "30px 0 50px 16.1% ",width:'67%' }}>
            {zhuanshu}
          </div>
        </div>
        <img style={{width:'100%'}} src={require('../../static/images/home/nengli/datu9.png')} />
        <img style={{width:'100%'}} src={require('../../static/images/home/nengli/datu10.png')} />
        <img style={{width:'100%'}} src={require('../../static/images/home/nengli/datu11.png')} />




        <div
            className="zhenghe overhidden common_item_wrap"
            style={{ backgroundColor: '#ffffff' }}
          >
            <div
              className="flexColumn newretail_titlewrap"
              style={{ marginBottom: '35px' }}
            >
              <div className="newretail_titlewrap_title" style={{marginTop:50,fontWeight:800,fontSize:18}}>
              申请免费试用
              </div>
              {/* <div className="newretail_titlewrap_descp">
                留下您的联系方式，我们将有专人与您联系
              </div> */}
            </div>
            <div
              className="common_wrap  flexBetween"
              style={{
                margin: '76px auto',
                height: '118px',
                width: '1076px',
              }}
            >
              <div
                style={{
                  width: '30%',
                  height: '38px',
                  float: 'left',
                  marginRight: '32px',
                  marginBottom: '30px',
                }}
              >
                <Input ref="name" placeholder="请输入您的姓名" />
              </div>
              <div
                style={{
                  width: '30%',
                  height: '38px',
                  float: 'left',
                  marginBottom: '30px',
                }}
              >
                <Input ref="phone" placeholder="请输入您的联系电话" />
              </div>
              <div
                style={{
                  width: '30%',
                  height: '38px',
                  float: 'right',
                  // marginRight: '32px',
                  marginBottom: '30px',
                  margin:'0 32px',
                }}
              >
                <Input ref="teamName" placeholder="请填写您的职位" />
              </div>
              <div
                style={{
                  width: '97%',
                  height: '88px',
                  float: 'left',
                  marginBottom: '30px',
                }}
              >
                <TextArea ref="types" id='types' style={{height:90,maxHeight:90}}  placeholder="请输入留言内容" />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '48px',
                  float: 'right',
                  marginBottom: '30px',
                  marginRight: '32px',
                }}
              >
                <Button
                  style={{
                    width: '193px',
                    height: '38px',
                    background: '#1e2540',
                    color: '#fff',
                    float: 'right',
                  }}
                  onClick={(e) => this.getAdd()}
                  loading={isloading}
                >
                  提交
                </Button>
              </div>
            </div>
          </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => ({ //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad
})

export default connect(mapStateToProps)(Lexin)
