import $ from 'jquery'

var Global = (function () {
  //获取页面传值参数
  function getPageParams() {
    var url = window.location.href
    var option = {}
    if (url.indexOf("?") > -1) {
      var arr = url.split("?")[1].split("&")
      arr.forEach(function (str) {
        var arrTemp = str.split("=")
        option[arrTemp[0]] = arrTemp[1]
      })
    }
    return option
  }
  //图片放大器（官网 企业资质页面）
  function tupianFangda(imgEl, itemList, imgKey, curIndex) {
    // {
    //   title: 'B2B交易管理系统',
    //   imgListIndex: 0,
    //   sm: require('../../static/images/rights/B2Bjiaoyiguanlixitong.png'), lg: require('../../static/images/rights/B2Bjiaoyiguanlixitong.png')
    // },
    let imgSrc
    let index = curIndex
    if ($(imgEl).attr("data-lgimg") && $(imgEl).attr("data-lgimg") !== '') {
      imgSrc = $(imgEl).attr("data-lgimg")
    } else {
      imgSrc = imgEl.src
    }
    // imgEl.onload = function () {
    //   console.log(12222)
    //   let w = this.width
    //   let h = this.height
    //   console.log(w, h)
    //   if ((w / h) <= ($div.width() / $div.height())) {
    //     this.style.width = '100%'
    //     this.style.height = "auto"
    //   } else {
    //     this.style.width = "auto"
    //     this.style.height = '100%'
    //   }
    // }
    var $div = $(
      '<div class="imgFangdaWrap" style="position:relative;z-index:100;">' +
      '<div style="position:fixed;top:0;bottom:0;left:0;right:0;background-color:rgba(0,0,0,0.5);z-index:1;"></div>' +
      '<div class="showFangdaImgWrap" style="position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center;z-index:2;display:flex;align-items:center;justify-content: center;">' +
      '<img class="showFangdaImg" src="' + imgSrc + '" style="max-height:100%;max-width:100%;"/>' +
      '<span class="closeImg" style="cursor:pointer;position:absolute;top:0;right:0;color:#fff;font-size:26px;padding:0 10px;">&times;</span>' +

      '<span class="leftImg" style="display:none;cursor: pointer;position: absolute;top: calc(50% - 60px);left: 30px;color: #fff;font-size: 30px;padding: 48px 10px;background-color: rgba(255,255,255,0.1);display: flex;align-items: center;justify-content: center;">&lt;</span>' +
      '<span class="rightImg" style="display:none;cursor: pointer;position: absolute;top: calc(50% - 60px);right: 30px;color: #fff;font-size: 30px;padding: 48px 10px;background-color: rgba(255,255,255,0.1);display: flex;align-items: center;justify-content: center;">&gt;</span>' +
      '</div>' +
      '</div>'
    )
    function cutImg(step) { //number
      let indexTemp = index + step
      let newIndex
      if (indexTemp > itemList.length - 1) {
        newIndex = 0
      } else if (indexTemp < 0) {
        newIndex = itemList.length - 1
      } else {
        newIndex = indexTemp
      }
      $div.find(".showFangdaImg").attr("src", itemList[newIndex][imgKey])
      index = newIndex
    }
    //点击叉号关闭
    $div.find(".closeImg").click(function () {
      if ($(".imgFangdaWrap").length > 0) {
        $(".imgFangdaWrap").remove()
        $("body").css("overflow", "auto")
      }
    })
    $div.find(".leftImg").click(function (e) {
      e.stopPropagation()
      cutImg(-1)
    })
    $div.find(".rightImg").click(function (e) {
      e.stopPropagation()
      cutImg(1)
    })
    if (itemList && itemList.length > 1) {
      $div.find(".leftImg")
        .add($div.find(".rightImg"))
        .show()
    }
    //点击其他地方也关闭
    $div.click(function (e) {
      let ele = e.target
      let tabName = ele.tagName
      console.log(tabName)
      if (tabName.toLocaleLowerCase() !== "img") {
        $(".imgFangdaWrap").remove()
        $("body").css("overflow", "auto")
      }
    })
    $("body").append($div)
    $("body").css("overflow", "hidden") //禁止body滚动
    $("body").find(".imgFangdaWrap .showFangdaImgWrap").css({
      "width": "95%",
      "height": "95%",
      "max-width": "1600px",
      "min-widht": "500px",
      "max-height": "1200px",
      "min-height": "500px"
    })
  }
  //--------------------------------------------------------
  return {
    getPageParams,
    tupianFangda
  }
})();

export default Global