import React, { Component } from 'react'
// import PropTypes from 'prop-types'
//redux
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
// 路由
import { Link } from 'react-router-dom'
//antd组件
import { Row, Col } from 'antd'
// 样式
import './Header.less'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //透明度
      headerOpacity: 0,

      tabList: [
        { text: '云派工简介', link: '/' },
        { text: '师傅入驻', link: '/master' },
        { text: '云派工赋能', link: '/enterprise' },
      ],
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //滚动
  handleScroll = () => {
    let carouselHeight
    if (document.getElementsByClassName('ant-carousel')[0]) {
      carouselHeight = document.getElementsByClassName('ant-carousel')[0]
        .clientHeight
    } else {
      carouselHeight = document.getElementsByClassName('page_bg')[0]
        .clientHeight
    }
    let srcollTop =
      document.documentElement.scrollTop ||
      window.pageYOffset ||
      document.body.scrollTop
    //修改header透明度
    let newOpacity = srcollTop / carouselHeight
    this.setState({ headerOpacity: newOpacity })
  }
  //点击导航
  clickTab = (idx) => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(idx))
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const { headerOpacity, tabList } = this.state
    const { ad } = this.props
    //logo
    // let logoSrc = headerOpacity > 0.7 ? require('../../static/images/LOGO_black.png') : require('../../static/images/LOGO_white.png')
    let logoSrc = require('../../static/images/LOGO.png')
    let tabs = tabList.map((obj, index) => (
      <Link
        key={index}
        className={
          (headerOpacity > 0.7 ? 'black' : 'white') +
          (index === ad.curTabIndex ? ' active' : '')
        }
        style={{margin:'0 30px'}}
        onClick={() => {
          this.clickTab(index)
        }}
        to={obj.link}
      >
        {obj.text}
      </Link>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div
        className="header"
        style={{
          backgroundColor: 'rgba(255, 255, 255, ' + headerOpacity + ')',
        }}
      >
        {/* <Row
          type="flex"
          align="middle"
          // justify="space-between"
          // style={{ minWidth: '710px' }}
        > */}
          {/* <Col
            sm={{ span: 3, offset: 0 }}
            md={{ span: 4, offset: 1 }}
            lg={{ span: 6, offset: 1 }}
            xl={{ span: 8, offset: 5 }}
            xxl={{ span: 10, offset: 2 }}
          > */}
            <img className="header_logo" style={{width:86,marginLeft:'20%',float:'left'}} src={logoSrc} alt="" />
          {/* </Col> */}
          {/* <Col
            sm={{ span: 21, offset: 0 }}
            md={{ span: 19, offset: 0 }}
            lg={{ span: 17, offset: 0 }}
            xl={{ span:8, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          > */}
            <div className="link_wrap flexCenter" style={{float:'left',marginLeft:'35%'}}>{tabs}</div>
          {/* </Col> */}
          {/* <Col
             sm={{ span: 7, offset: 0 }}
             md={{ span: 9, offset: 0 }}
             lg={{ span: 17, offset: 0 }}
             xl={{ span: 3, offset: 0 }}
             xxl={{ span: 12, offset: 0 }}
          >
            <div className="link_wrap flexCenter" style={{color:'#fff'}} >
              <img src = {require('../../static/images/phone.png')}  style={{marginRight:5}}/>
              <div style={{fontWeight:200}}>400-060-8060</div></div>
          </Col> */}
        {/* </Row> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})

export default connect(mapStateToProps)(Header)
