import React from 'react'
import axios from 'axios'
import { stringify } from 'qs'
// import { router } from 'umi'
//组件
import { message } from 'antd'
//安全
import xss from 'xss' //防止xss注入攻击
//变量方法
import { globalHost,  } from './utils'
import { loginStateKey } from './consts'

let timer
//超时
const timeOut = (delay) => {
  return new Promise((resolve, reject) => {
    timer = window.setTimeout(() => {
      resolve({
        resultCode: '100',
        systemMessage: '网络连接超时，请稍后再试',
      })
    }, delay)
  })
}
//请求func
const request_real = (type, url, headers, data, responseType) => {
  return new Promise((resolve, reject) => {
    let option = {
      method: type,
      url: url,
      headers: headers,
      data: data,
      processData: false,
      cache: false,
    }
    if (responseType) option.responseType = responseType
    axios(option)
      .then((response) => {
        window.clearTimeout(timer)
        resolve(response.data)
      })
      .catch((error) => {
        window.clearTimeout(timer)
        resolve(error)
      })
  })
}

const requestw = async ({
  type = 'post',
  url,
  headers,
  data,
  delay = 240000,
  responseType,
}) => {
  //delay 都是四分钟
  //处理各种参数
  // let token =
  //   localDB.getItem(loginStateKey) && localDB.getItem(loginStateKey).loginInfo && localDB.getItem(loginStateKey).loginInfo.loginSessionId
  //     ? localDB.getItem(loginStateKey).loginInfo.loginSessionId
  //     : null
  let typeTemp, urlTemp, dataTemp, headersTemp
  let dataTemp0 = {
    ...data,
  }
  //过滤一下dataTemp0
  for (let key in dataTemp0) {
    if (dataTemp0[key] === undefined || dataTemp0[key] === null) {
      delete dataTemp0[key]
    }
  }
  //过滤一下dataTemp0 end
  switch (type) {
    case 'get':
      typeTemp = type
      urlTemp =
        url.indexOf('http:') > -1 || url.indexOf('https:') > -1
          ? url
          : globalHost() +
          url +
          '?' +
          xss(stringify(dataTemp0)) 
          // '&sessionId=' +
          // token
      dataTemp = null
      break
    case 'post':
      typeTemp = type

      urlTemp =
        url.indexOf('http:') > -1 || url.indexOf('https:') > -1
          ? url
          : globalHost() + url
      dataTemp = xss(stringify(dataTemp0)) //序列化url形式
      break
    case 'formdata':
      typeTemp = 'post'
      urlTemp =
        url.indexOf('http:') > -1 || url.indexOf('https:') > -1
          ? url
          : globalHost() + url
      let formData = new FormData()
      for (let key in dataTemp0) {
        if (dataTemp0[key] !== undefined && dataTemp0[key] !== null) {
          let typeStr = typeof dataTemp0[key]
          formData.append(
            key,
            typeStr === 'object' ? dataTemp0[key] : xss(dataTemp0[key])
          )
        }
      }
      dataTemp = formData
      break
    default:
      break
  }
  if (type !== 'get') urlTemp = urlTemp //+ '?sessionId=' + token
  headersTemp = {
    //Authorization: token,
    ...headers,
  }
  //处理各种参数 end

  let p1 = timeOut(delay)
  let p2 = request_real(typeTemp, urlTemp, headersTemp, dataTemp, responseType)

  //p1 p2赛跑
  return Promise.race([p1, p2]).then((res) => {
    if (res && res.code == 9999 && res.message.indexOf('非法') > -1) {
      // router.replace('/user/login')
      setTimeout(() => {
        window.location.reload()
      }, 300)
    }
    return res
  })
}

export default requestw
