import React, { Component } from 'react'
// import $ from "jquery"
// 组件
import PageBg from '../../components/PageBg/PageBg'
import AppItem from '../../components/AppItem/AppItem'
//变量
import { mobileW } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
// 样式
import './Product.less'

const ScrollOverPack = ScrollAnim.OverPack;

export default class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //产品列表
      appList: [
        {
          imgSrc: require("../../static/images/app_jdtongxin_com.png"),
          title: "京东通信（商用版）",
          tabs: "版本：1.0.4 更新时间：2019.03.05 大小:37MB",
          features: "通信行业渠道销售系统",
          advantage: "系统简单易用、运营商官方授权、京东特供进货渠道、低门槛、高收益",
          scenes: "通信渠道、中小零售门店产品采购，用户面对面销售",
          version: "安卓，IOS",
          qrCode: require("../../static/images/qr_com.jpg"),
        }, {
          imgSrc: require("../../static/images/app_jdtongxin_wl_new.png"),
          title: "京信",
          tabs: "版本：1.0.4 更新时间：2019.03.05 大小:37MB",
          features: "运营商渠道互联网号卡发展平台",
          advantage: "产品易用，自动化办理，数据统计清晰",
          scenes: "快递交付时小哥的面对面业务受理、增值服务随销推介",
          version: "安卓，IOS",
          qrCode: require('../../static/images/qr_wl_new.png'),
        }, {
          imgSrc: require("../../static/images/app_hehuoren.png"),
          title: "和伙人",
          tabs: "版本：1.0.4 更新时间：2019.03.05 大小:37MB",
          features: "为末梢门店提供酬金垫付，提供业务受理平台",
          advantage: "移动业务一键办理，操作简便，T+1结算酬金",
          scenes: "面对面营销，入企营销，线上营销",
          version: "安卓",
          qrCode: require('../../static/images/qr_hehuoren.jpg'),
        }
      ],
      //一堆图标
      appIconList: [
        { imgSrc: require('../../static/images/通信+.png'), title: '通信+' },
        { imgSrc: require('../../static/images/沃店+.png'), title: '沃店+' },
        { imgSrc: require('../../static/images/掌沃.png'), title: '掌沃' },
        { imgSrc: require('../../static/images/和分销.png'), title: '和分销' },
        { imgSrc: require('../../static/images/京东到家.png'), title: '京东到家' },
        { imgSrc: require('../../static/images/和伙伴.png'), title: '和伙伴' },
        // { imgSrc: require('../../static/images/4G+.png'), title: '4G+' },
        { imgSrc: require('../../static/images/扫码购.png'), title: '扫码购' },
        { imgSrc: require('../../static/images/火星人.png'), title: '火星人' },
        { imgSrc: require('../../static/images/京东小哥.png'), title: '京东小哥' },
        { imgSrc: require('../../static/images/蜜蜂网.png'), title: '蜜蜂网' },
        // { imgSrc: require('../../static/images/中悦.png'), title: '中悦' },
        // { imgSrc: require('../../static/images/米店.png'), title: '米店' }
        // { imgSrc: require('../../static/images/长春金鹏.png'), title: '长春金鹏' },
        // { imgSrc: require('../../static/images/沈阳士强.png'), title: '沈阳士强' },
        // { imgSrc: require('../../static/images/恒佳美达.png'), title: '恒佳美达' },
        // { imgSrc: require('../../static/images/兴瑞.png'), title: '兴瑞' }
      ],
      marginRight: 0,
      activeIndex: null,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.appItemCss) //监听窗口大小改变
    this.appItemCss()
  }
  componentWillUnmount() { //一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
    window.removeEventListener('resize', this.appItemCss)
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  appItemCss = () => {
    let wrapWidth = document.getElementsByClassName("appIcon_wrap")[0].clientWidth
    console.log(wrapWidth)
    let marginRight = (wrapWidth - (70 + 2) * 5) / 4
    console.log(marginRight)
    this.setState({
      marginRight: marginRight
    })
  }
  onActive = (index) => {
    this.setState({
      activeIndex: index ? index.toString() : null
    })
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const { activeIndex } = this.state

    //产品列表
    let appListDiv = this.state.appList.map((obj, index) => (
      <div className='case_app_item' key={index}>
        <div className='imgBox'>
          <img className='img100' src={obj.imgSrc} alt='' />
        </div>
        <div className='case_content'>
          <div style={{ marginBottom: '20px' }}>
            <div style={{ color: '#4A4A4A', fontSize: '21px', marginBottom: '10px' }}>{obj.title}</div>
            <div style={{ color: '#9B9B9B', fontSize: '12px' }}>{obj.tabs}</div>
          </div>
          <div style={{ color: '#4A4A4A', fontSize: '14px', lineHeight: '35px', padding: '20px 0' }}>
            {
              window.screen.width > mobileW ?
                <div style={{ height: "140px" }}>
                  <ScrollOverPack playScale={[0.15, 0.15]}>
                    <QueueAnim type={['right', 'left']}
                      leaveReverse={true}
                      forcedReplay={true}
                      delay={10}
                    >
                      <div key="1">产品功能：{obj.features}</div>
                      <div key="2">产品优势：{obj.advantage}</div>
                      <div key="3">应用场景：{obj.scenes}</div>
                      <div key="4">应用版本：{obj.version}</div>
                    </QueueAnim>
                  </ScrollOverPack>
                </div>
                :
                <div style={{ height: "140px" }}>
                  <QueueAnim type={['right', 'left']}
                    leaveReverse={true}
                    forcedReplay={true}
                    delay={10}
                  >
                    <div key="1">产品功能：{obj.features}</div>
                    <div key="2">产品优势：{obj.advantage}</div>
                    <div key="3">应用场景：{obj.scenes}</div>
                    <div key="4">应用版本：{obj.version}</div>
                  </QueueAnim>
                </div>
            }
          </div>
        </div>
        {
          window.screen.width > mobileW ?
            <ScrollOverPack playScale={[0.15, 0.15]} className="qrCode_box">
              <QueueAnim type={['bottom', 'top']}
                leaveReverse={true}
                forcedReplay={true}
                delay={10}
              >
                <div className='qrCode_box' key="1">
                  <div className='qrCode_img_box'>
                    <img className='img100' src={obj.qrCode} alt='' />
                  </div>
                  <div className='qrCode_text'>下载体验</div>
                </div>
              </QueueAnim>
            </ScrollOverPack>
            :
            <QueueAnim type={['bottom', 'top']}
              leaveReverse={true}
              forcedReplay={true}
              delay={10}
            >
              <div className='qrCode_box' key="1">
                <div className='qrCode_img_box'>
                  <img className='img100' src={obj.qrCode} alt='' />
                </div>
                <div className='qrCode_text'>下载体验</div>
              </div>
            </QueueAnim>
        }
      </div>
    ))
    //一堆图标
    let appIconDiv = this.state.appIconList.map((obj, index) => (
      <div key={index}>
        <QueueAnim type={['bottom', 'top']}
          leaveReverse={true}
          forcedReplay={true}
          delay={10}
        >
          <div className={`appIcon_item${activeIndex && index.toString() === activeIndex.toString() ? " active" : ""}`}
            key={index}
            style={{ marginRight: ((index + 1) % 5 === 0 ? "0px" : this.state.marginRight) }}
            onMouseEnter={() => { this.onActive(index.toString()) }}
            onMouseLeave={() => { this.onActive(null) }}
          >
            <AppItem titleM={obj.title} imgSrc={obj.imgSrc} />
          </div>
        </QueueAnim>
      </div>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className='case'>
        <PageBg bgSrc={require('../../static/images/case_bg.png')}
          titleM='产品案例'
          descp='我们有服务大客户的能力，但我们更关注中小企业持续发展'
        />
        <div className='page_container posiRe'>
          <div className='case_app_item_wrap'>
            {appListDiv}
          </div>
          {
            window.screen.width > mobileW ?
              <div style={{ height: "385px" }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <div className='appIcon_wrap'>
                    {appIconDiv}
                  </div>
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: "385px" }}>
                <div className='appIcon_wrap'>
                  {appIconDiv}
                </div>
              </div>
          }
        </div>
      </div>
    )
  }
}
