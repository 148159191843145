/**
 * 得到rootReducer
 */
import { combineReducers } from 'redux'
//reducers
import ad from './adReducer'

const rootReducer = combineReducers({
  ad,
})

export default rootReducer