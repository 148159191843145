import React, { Component } from 'react'
//antd组件
import { Icon } from 'antd'
// 组件
import PageBg from '../../components/PageBg/PageBg'
import Carousel3d from '../../components/Carousel3d/Carousel3d'
import Global from '../../Global'
// 样式
import './Qualification.less'

export default class Qualification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      xukeList: [
        { title: 'CMMI 3级认证', sm: require('../../static/images/CMMI3jirenzhengzhengshu.png'), lg: require('../../static/images/CMMI3jirenzhengzhengshu_lg.jpg') },
        { title: '信息安全管理体系认证', sm: require('../../static/images/信息安全认证.png'), lg: require('../../static/images/信息安全认证_lg.png') },
        { title: '服务管理体系认证', sm: require('../../static/images/服务管理认证.png'), lg: require('../../static/images/服务管理认证_lg.png') },
        { title: '质量管理认证', sm: require('../../static/images/质量管理认证.png'), lg: require('../../static/images/质量管理认证_lg.jpg') },
        { title: '高新技术企业', sm: require('../../static/images/高新技术企业证书.png'), lg: require('../../static/images/高新技术企业证书_lg.jpg') },
      ],
      zhuanliList: [
        { title: '活体认证', sm: require('../../static/images/一种实名制意外保险自助销售设备.png'), lg: require('../../static/images/活体认证_lg.jpg') },
        { title: '人脸识别', sm: require('../../static/images/一种基于活体的真人身份验证终端.png'), lg: require('../../static/images/人脸识别_lg.jpg') },
        { title: '实名制', sm: require('../../static/images/一种融合用户多生物特征的活体认证支付系统.png'), lg: require('../../static/images/实名制_lg.jpg') },
        { title: '蓝牙写卡', sm: require('../../static/images/一种蓝牙写卡器.png'), lg: require('../../static/images/蓝牙写卡_lg.jpg') },
        { title: '活体支付', sm: require('../../static/images/一种基于人脸识别的身份对比核查装置.png'), lg: require('../../static/images/活体支付_lg.jpg') },
        { title: '自助销售', sm: require('../../static/images/一种实名制意外保险自助销售设备.png'), lg: require('../../static/images/自助销售_lg.jpg') },
      ],
      rightList: [
        {
          title: 'B2B交易管理系统',
          imgListIndex: 0,
          sm: require('../../static/images/rights/B2Bjiaoyiguanlixitong.png'), lg: require('../../static/images/rights/B2Bjiaoyiguanlixitong.png')
        },
        {
          title: '充值缴费管理系统',
          imgListIndex: 1,
          sm: require('../../static/images/rights/chongzhijiaofeiguanlixitong.png'), lg: require('../../static/images/rights/chongzhijiaofeiguanlixitong.png')
        },
        {
          title: '电信业务B2b2C分销管理',
          imgListIndex: 2,
          sm: require('../../static/images/rights/dianxinyewuB2B2Cfenxiao.png'), lg: require('../../static/images/rights/dianxinyewuB2B2Cfenxiao.png')
        },
        {
          title: '和伙伴APP软件',
          imgListIndex: 3,
          sm: require('../../static/images/rights/hehuobanAPPruanjian.png'), lg: require('../../static/images/rights/hehuobanAPPruanjian.png')
        },
        {
          title: '和伙人APP软件',
          imgListIndex: 4,
          sm: require('../../static/images/rights/hehuorenAPPruanjian.png'), lg: require('../../static/images/rights/hehuorenAPPruanjian.png')
        },
        {
          title: '和小店APP软件',
          imgListIndex: 5,
          sm: require('../../static/images/rights/hexiaodianAPPruanjian.png'), lg: require('../../static/images/rights/hexiaodianAPPruanjian.png')
        },
        {
          title: '零售店ERP管理系统',
          imgListIndex: 6,
          sm: require('../../static/images/rights/lingshoudianERP.png'), lg: require('../../static/images/rights/lingshoudianERP.png')
        },
        {
          title: '码上办管理平台系统',
          imgListIndex: 7,
          sm: require('../../static/images/rights/mashangbanguanli.png'), lg: require('../../static/images/rights/mashangbanguanli.png')
        },
        {
          title: '扫码购APP软件',
          imgListIndex: 8,
          sm: require('../../static/images/rights/saomagouAPPruanjian.png'), lg: require('../../static/images/rights/saomagouAPPruanjian.png')
        },
        {
          title: '数字化管理系统',
          imgListIndex: 9,
          sm: require('../../static/images/rights/shuzihuaguanli.png'), lg: require('../../static/images/rights/shuzihuaguanli.png')
        },
        {
          title: '佣金结算系统',
          imgListIndex: 10,
          sm: require('../../static/images/rights/yongjinjiesuanxitong.png'), lg: require('../../static/images/rights/yongjinjiesuanxitong.png')
        },
        {
          title: '资源管理系统',
          imgListIndex: 11,
          sm: require('../../static/images/rights/ziyuanguanlixitong.png'), lg: require('../../static/images/rights/ziyuanguanlixitong.png')
        },

        //新增
        {
          title: '智能代付系统',
          imgListIndex: 12,
          sm: require('../../static/images/rights/zhinengdaifuxitong.jpg'), lg: require('../../static/images/rights/zhinengdaifuxitong.jpg')
        },
        {
          title: '智能结算系统',
          imgListIndex: 13,
          sm: require('../../static/images/rights/zhinengjiesuanxitong.jpg'), lg: require('../../static/images/rights/zhinengjiesuanxitong.jpg')
        },
        {
          title: '智能提现APP软件',
          imgListIndex: 14,
          sm: require('../../static/images/rights/zhinengtixianappruanjian.jpg'), lg: require('../../static/images/rights/zhinengtixianappruanjian.jpg')
        },
        {
          title: 'SaaS结算系统',
          imgListIndex: 15,
          sm: require('../../static/images/rights/saasjiesuanxitong.jpg'), lg: require('../../static/images/rights/saasjiesuanxitong.jpg')
        }
      ],
      //许可高度
      xukeHeight: 200,
      //专利尺寸
      zhuanliWrapWidth: 0,
      zhuanliItemWidth: 0,
      wrapLeft: 0,
      wrapLeftMin: 0,
      zhuanliWrapHeight: 300,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
    //设置许可高度
    let xuke_wrap_w = document.getElementsByClassName("xuke_wrap")[0].clientWidth
    let xukeHeight = xuke_wrap_w * 0.15;
    //设置许可高度 end  
    //设置专利item宽度 wrap宽度 最小left
    let w = document.getElementsByClassName("zhuanli_wrap")[0].clientWidth / 5
    let wrapW = w * this.state.zhuanliList.length
    let wrapLeftMin = -(w * (this.state.zhuanliList.length - 5))
    //设置专利item宽度 wrap宽度 最小left end

    this.setState({
      xukeHeight,

      zhuanliWrapWidth: wrapW,
      zhuanliItemWidth: w,
      wrapLeftMin: wrapLeftMin,
    })
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  clickLeft = () => {
    let oldLeft = this.state.wrapLeft
    if (oldLeft >= 0) {
      return
    } else {
      this.setState({
        wrapLeft: oldLeft + this.state.zhuanliItemWidth
      })
    }
  }
  clickRight = () => {
    let oldLeft = this.state.wrapLeft
    if (oldLeft <= this.state.wrapLeftMin) {
      return
    } else {
      this.setState({
        wrapLeft: oldLeft - this.state.zhuanliItemWidth
      })
    }
  }
  clickXukeImg = (e) => {
    let ele = e.target
    let imgIndex = Number(ele.getAttribute("data-imgindex"))
    Global.tupianFangda(
      ele,
      this.state.xukeList,
      "lg",
      imgIndex
    )
  }
  clickZhuanliImg = (e) => {
    let ele = e.target
    let imgIndex = Number(ele.getAttribute("data-imgindex"))
    Global.tupianFangda(
      ele,
      this.state.zhuanliList,
      "lg",
      imgIndex
    )
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    //认证许可
    let xukeDiv = this.state.xukeList.map((obj, index) => {
      return (
        <div className='xuke_item flexColumn' key={index}>
          <img src={obj.sm} alt=''
            data-lgimg={obj.lg}
            onClick={this.clickXukeImg}
            data-imgindex={index}
            style={{ height: this.state.xukeHeight }}
          />
          <div style={{ fontSize: '12px', marginTop: "15px" }}>{obj.title}</div>
        </div>
      )
    })
    //研发专利
    let zhuanliDiv = this.state.zhuanliList.map((obj, index) => (
      <div className='zhuanli_item flexColumn' key={index} style={{ width: this.state.zhuanliItemWidth + 'px' }}>
        <img src={obj.sm} alt=''
          data-lgimg={obj.lg}
          onClick={this.clickZhuanliImg}
          data-imgindex={index}
        />
        <div style={{ fontSize: '12px', margin: '15px 0 5px' }}>{obj.title}</div>
      </div>
    ))
    console.log(this.state.wrapLeft, this.state.wrapLeftMin)
    return (
      <div className='qualification'>
        <PageBg bgSrc={require('../../static/images/qualification_bg.png')}
          titleM='企业资质'
          descp='我们唯一的目标，就是客户成功'
        />

        <div className='page_container common_wrap'>
          {/* 企业认证 */}
          <div>
            <div className='qualification_title'>企业认证</div>
            <div className='xuke_wrap flexCenter flexJCenter'>
              {xukeDiv}
            </div>
          </div>

          {/* 研发专利 */}
          <div className='zhuanli_wrap_wrap posiRe'>
            <div className='qualification_title'>研发专利</div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='leftBtn flexCenter flexJCenter posiRe'
                onClick={this.clickLeft}
                style={{ visibility: this.state.wrapLeft >= 0 ? "hidden" : "visible" }}
              >
                <Icon type="left" />
              </div>

              <div className='zhuanli_wrap' style={{ height: this.state.zhuanliWrapHeight }}>
                {/* ul wrap */}
                <div className='zhuanli_item_wrap clearfix'
                  style={{ width: this.state.zhuanliWrapWidth, left: this.state.wrapLeft }}
                >
                  {zhuanliDiv}
                </div>
              </div>

              <div className='rightBtn flexCenter flexJCenter posiRe'
                onClick={this.clickRight}
                style={{ visibility: this.state.wrapLeft === this.state.wrapLeftMin ? "hidden" : "visible" }}
              >
                <Icon type="right" />
              </div>
            </div>
          </div>

          {/* 软件著作权 */}
          <div>
            <div className='qualification_title'>软件著作权</div>
            <div>
              <Carousel3d itemList={this.state.rightList} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
