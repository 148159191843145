import React, { Component } from "react";
// 样式
import "./SuccessCase.less";

export default class SuccessCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearList: ["2014", "2015", "2016", "2017", "2018", "2019"],
      descpList: [
        [
          <span>通信行业<span style={{ color: "red" }}>第⼀家</span>O2O商城平台—辽宁联通⽹网上商城</span>,
          <span>创建业内<span style={{ color: "red" }}>第⼀家</span>一点式集中受理的中台服务模式</span>
        ],
        [
          <span>通信行业<span style={{ color: "red" }}>第⼀家</span>通过移动互联⽹模式实现运营商业务受理的公司</span>,
          <span>通信行业<span style={{ color: "red" }}>第⼀家</span>设计开发硬件设备远程写卡开户公司</span>
        ],
        [
          <span>与辽宁联通合作SaaS模式，⾃主开发全国<span style={{ color: "red" }}>第⼀个</span>受理APP客户端-“掌沃”</span>,
          <span>发布<span style={{ color: "red" }}>第⼀个</span>通信行业SaaS受理平台-“通信+”</span>
        ],
        [
          <span>与辽宁移动合作，⾃主开发中国移动集团<span style={{ color: "red" }}>第⼀个</span>渠道管理平台-“和伙人”</span>,
          <span>通信行业<span style={{ color: "red" }}>第⼀家</span>提供平台开发+运营支撑+快速结酬 +税筹等一站式服务提供商</span>
        ],
        [
          <span>与京东商城、京邦达合作， 成为中国电信集团唯一面对面实名交付技术服务商</span>,
          <span>与卓望数码合作，为中国移动集团设计并开发中国移动新零售B2B平台方案</span>
        ],
        [
          <span>与京东商城、京邦达合作， 成为中国移动集团唯一⾯对⾯实名交付技术服务商</span>,
          <span>与京东商城合作，⾃主开发并运营“京东通信”通信行业最大的分销平台</span>
        ]
      ],
      curIndex: 0
    };
  }
  componentDidMount() {
    this.initInterval();
  }
  componentWillUnmount() {
    this.destroyInterval();
  }
  changeYear = idx => {
    this.destroyInterval();
    this.setState({
      curIndex: idx
    });
  };
  //开始定时器
  initInterval = () => {
    this.timer = setInterval(() => {
      let curIndex = this.state.curIndex;
      if (curIndex === 5) {
        curIndex = 0;
      } else {
        curIndex++;
      }
      this.setState({
        curIndex: curIndex
      });
    }, 2000);
  };
  destroyInterval = () => {
    clearInterval(this.timer);
  };
  render() {
    //圆点
    let arrTemp = new Array(6).fill(0);
    let dianDiv = arrTemp.map((ele, index) => {
      return (
        <div
          key={index}
          className={"dian" + (index === this.state.curIndex ? " active" : "")}
        />
      );
    });
    // 年份
    let yearDiv = this.state.yearList.map((str, index) => (
      <div
        className={"year" + (index === this.state.curIndex ? " active" : "")}
        key={index}
        onMouseEnter={() => {
          this.changeYear(index);
        }}
        onMouseLeave={this.initInterval}
      >
        {str}
      </div>
    ));
    // 描述
    let descps = this.state.descpList[this.state.curIndex];
    let descpDiv = descps.map((str, index) => <li key={index}>{str}</li>);
    return (
      <div className="successCase overhidden">
        <div className="common_wrap">
          <div className="flexColumn home_titlewrap">
            <div className="home_titlewrap_title">成功案例</div>
            <div className="home_titlewrap_descp">
              专业创造价值 服务成就客户
            </div>
          </div>

          <div className="range flexCenter flexAround">{dianDiv}</div>
          <div className="year_wrap flexCenter flexAround">{yearDiv}</div>

          <div
            className="descp_wrap"
            onMouseEnter={this.destroyInterval}
            onMouseLeave={this.initInterval}
          >
            <ul>{descpDiv}</ul>
          </div>
        </div>
      </div>
    );
  }
}
