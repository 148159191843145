import React, { Component } from 'react'
// 组件
import PageBg from '../../components/PageBg/PageBg'
// //变量
// import { mobileW } from '../../utils/utils'
// 样式
import './JoinUs.less'

export default class JoinUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabList: [
        {
          text: "技术类", jobs: [
            {
              job: "Java资深开发工程师 1人 10-15k",
              content: ["1、按照公司项目规划，负责公司各类应用项目的架构、方案设计；",
                "2、需求分析：负责项目的技术需求的收集、整理和分析；",
                "3、架构设计：确定项目或产品的技术架构设计和整体技术路线、架构走向，研究引入新的技术、方法和编程思想",
                "4、技术评审：对项目或产品中的总体设计、模块设计进行确定、审查和把关；",
                "5、构件整理：整理部门中的基础构件和业务构件，进行产品化改造及核心代码的编写；",
                "6、技术指导：对技术人员进行技术培训，指导高级研发人员在整体架构下开展详细设计开发工作；",
                "7、问题解决：负责项目前期的技术探索、关键技术和算法的实现，及时解决项目开发或产品研发中的技术难题；"],
              demand: ["1. 5年以上Java主流语言的软件研发经验；",
                "2. 有大中型复杂系统的架构设计经验；",
                "3. 熟悉互联网产品构建的相关技术，如负载均衡、全文索引、容器化、微服务等；",
                "4. 熟悉JavaEE体系架构，精通Spring Cloud、Spring Boot 、iBatis/myBatis等；",
                "5. 熟悉主流的微服务技术框架，如Spring Cloud，Dubbox,有丰富的相关项目经验。",
                "6. 熟悉 Linux 环境及常用命令，熟悉消息服务中间件；",
                "7. 熟悉 MySQL数据库，至少掌握一种缓存组件（Redis、Memcached）"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            },
            {
              job: "前端架构师 1人 10k-16k",
              content: ["负责产品前后端分离，技术架构，新技术预研，疑难问题分析解决等。"],
              demand: ["1. 计算机相关专业毕业, 4年及以上前端开发经验，1年以上架构级前端开发经历；",
                "2. 理解web标准和兼容性，对可用性相关知识有实际的了解和实践经验；",
                "3. 基本功扎实，熟悉W3C标准，精通前端开发技术(HTML5、JS、Ajax、Json、XHTML、CSS3)，了解各项技术的相关标准，对HTML、CSS、JavaScript有深刻理解，对HTTP协议、浏览器内核有深刻理解；",
                "4. 熟悉至少两种前端框架：React、Angular、Vue、jquery等, 熟悉至少一种前端构建工具：Gulp、Grunt、fis3、webpack等， 熟练SASS、LESS等预处理工具的使用, 对Vue了解深刻并且有实际项目经验者优先考虑。",
                "5. 精通面向对象的javascript开发，有开发过JS框架或公共组件开发经验，能实现模块封装，了解MVC等开发模式，熟练的使用一门后台技术（Nodejs/python/java/）；",
                "6. 熟悉Linux系统下开发环境，熟悉shell、gdb、vim、emacs等工具；",
                "7. 具备对系统优化重构的能力，有较强的学习能力和研究精神；",
                "8. 有个人技术博客或github项目者优先考虑。"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            },
            {
              job: "Web前端开发工程师 1人 6k-8k",
              content: ["1、负责Web端应用开发",
                "2、配合业务及产品完成需求",
                "3、配合后台开发人员实现产品功能, 有能力独立完成Web前端开发任务"],
              demand: ["1、精通使用React体系框架开发前后端分离项目",
                "2、精通CSS语法，能够复现原型图，例如antd可修改的默认样式",
                "3、精通小程序、H5整体开发及流程",
                "4、掌握JavaScript核心技术、DOM、Ajax、Json，ES6",
                "5、掌握菜单权限、图表，报表展示等能力"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            }
          ]
        },
        {
          text: "产品类", jobs: [
            {
              job: "产品经理 2人 6k-10k",
              content: ["1、规划和设计产品，参与设计原型和交互说明制作，参与用户测试与评估；",
                "2、组织产品开发项目，组织完成立项及实施；",
                "3、产品上线前期的测试，与开发人员沟通改进，撰写相关文档；",
                "4、负责产品设计开发的整体推进，参与产品推广计划制定和推广实施；",
                "5、通过业务数据分析、市场反应、客户反馈、精品分析等方式不断加深对业务需求的认识，不断提高产品的用户体验和价值。"],
              demand: ["1、本科学历、两年以上产品经理经验；",
                "2、熟练使用工具，熟练运用axure,viso、mindmnager等软件，对技术开发环节有了解者优先；",
                "3、熟悉产品实施过程，包括市场分析、需求分析、产品功能设计、业务流程设计、界面设计、用户研究和可用性测试等；",
                "4、有较强的用户需求判断、引导、控制能力，有良好的合作能力，善于合作协调沟通，思维敏捷，良好的书面和口头表达能力；",
                "5、具有较强的沟通能力、逻辑能力和产品设计能力，对数据敏感，具备较强分析加工能力；",
                "6、具有强烈的责任心和上进心，具备良好的团队合作精神，积极主动，能承受较大的工作压力。"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            }
          ]
        },
        {
          text: "销售类", jobs: [
            {
              job: "销售经理 2人 8k-10k",
              content: ["1、有一定运营商基础知识，熟练操作和使用公司的各种软件产品，并对客户有一定的影响力；",
                "2、积极开拓全国渠道客户，不断挖掘潜在客户，与客户确定商务条款，签订合同；",
                "3、及时收集、整理、反馈区域市场的需求变化、竞争动态等信息，提出改进产品质量、服务以及公司管理方面的意见和建议，配合公司完成相关的市场调研工作；",
                "4、负责客户售前的产品推介，协助客户搭建系统及人力架构；"],
              demand: ["1、本科及以上学历；",
                "2、电信运营商或代理商相关渠道管理经验2年以上（应届毕业生可酌情放宽）；",
                "3、具备客户开发及维护能力；",
                "4、良好的沟通与表达能力，口齿清晰、反应敏捷、耐心热情，学习能力强；",
                "5、该职位同时面向应届毕业生，可作为储备干部进行培训、跟帮带。"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            }
          ]
        },
        {
          text: "运营类", jobs: [
            {
              job: "运营经理 2人 6k-10k",
              content: ["1、负责京东电信项目的整体运营；",
                "2、对接京东物流集团总部的业务沟通协调及电信集团层面的业务沟通协调；",
                "3、对下属各省级大区区域经理工作进行管理指导，监控运营质量及异常；",
                "4、推动项目落地实施，向项目副总汇报。"],
              demand: ["1、本科以上学历，运营管理工作经验2年以上；",
                "2、有责任、有担当，懂运营懂管理；",
                "3、通信行业、零售行业或快消行业区域运营总监工作经验优先；",
                "4、可接受出差。"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            }
          ]
        },
        {
          text: "校招类", jobs: [
            {
              job: "运营助理2人 4-6k",
              content: ["1、负责京东物流集团层面的具体业务落实",
                "2、负责电信集团资源部门的业务对接，数据及管理落实",
                "3、负责实名制设备的收发管理、使用效率跟踪并能提出有效提升方案",
                "4、负责各区域订单量、外呼量、激活量的跟踪及数据分析，并根据数据能够提出有效意见和方法并实施",
                "5、配合运营总监，完成交代的待办事项"],
              demand: ["1、优秀应届生或工作经验2年左右。",
                "2、有良好的理解力和沟通表达力。"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            },
            {
              job: "产品助理 2人 4-6k",
              content: ["1、负责对市场部产品、电商产品制定具体的产品策略，制定产品开发迭代的规划和具体排期。",
                "2、负责对接技术部门跟踪产品的开发进度和开发流程，负责监督开发的实施，负责确保产品按时按规划上线。",
                "3、实时把握和挖掘用户需求，完成产品规划文档、产品需求文档、产品说明文档、编制和撰写设计方案，落地实施方案。"],
              demand: ["1、优秀应届生或工作经验2年左右。",
                "2、有良好的理解力和产品文档书写能力。"],
              // priority: "1. 具备大规模并行计算spark等开发经验者优先考虑。",
              // address: "沈阳"
            }
          ]
        }
      ],
      curIndex: 0
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  clickTab = (idx) => {
    this.setState({
      curIndex: idx
    })
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    //tab
    let tabDiv = this.state.tabList.map((obj, index) => {
      return (
        <div className={'tab_item' + (index === this.state.curIndex ? ' active' : '')}
          key={index}
          onClick={() => { this.clickTab(index) }}
        >
          {obj.text}
        </div>
      )
    })
    //job
    let objList = this.state.tabList[this.state.curIndex].jobs
    let objDiv = objList.map((obj, index) => {
      //工作职责
      let content = obj.content.map((str1, idx1) => (
        <p key={idx1} style={{ margin: '1px' }}>{str1}</p>
      ))
      //技能需求
      let demand = obj.demand.map((str2, idx2) => (
        <p key={idx2} style={{ margin: '1px' }}>{str2}</p>
      ))
      return (
        <div key={index} style={{ margin: '35px 0' }}>
          <div style={{ marginBottom: '16px' }}>{obj.job}</div>
          <div style={{ marginBottom: '16px' }}>
            工作职责：<br />
            {content}
          </div>
          <div style={{ marginBottom: '16px' }}>
            技能需求：<br />
            {demand}
          </div>
          {/* <div>
            有以下工作经验者优先：<br />
            {obj.priority}
          </div>
          <div>工作地点：{obj.address}</div> */}
        </div>
      )
    })
    return (
      <div className='joinus'>
        <PageBg bgSrc={require('../../static/images/joinUs_bg.png')}
          titleM='加入我们'
          descp='我们会带您面向未来，更往前一步'
        />

        <div className='page_container common_wrap'>
          <div className='tab_wrap flexCenter flexAround'>
            {tabDiv}
          </div>
          <div className='job_wrap'>
            {objDiv}
          </div>

          <div>有意者请将简历发送至lnbld@bld365.com</div>
        </div>
      </div>
    )
  }
}
